import { Chip, Grid, Icon, List } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, FilterSlice, riseStrings, useDispatch, useRouteMatch } from '@optum-uhone-hmkts/rise';
import { setDrawerStatus } from '../../actions/access_page_actions';
import { GetAllNotifications } from '../../actions/notification_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { ClientConnectSettings } from '../../sagas/clientConnect_sagas';
import { isIOS, isMobileDevice, isTablet } from '../../utilities/is_mobile';
import { AgentAvatar } from '../agent/agent_avatar';
import { navRoutes } from '../nav/Routes';
import { SvgIcon, IconType } from '../svgs/svg_icon';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AgentState } from '../../reducers/agent_reducer';
import { Logout } from '../../actions/authentication_actions';
import { jwt_auth } from '../../utilities/auth';
import { getRealmUrl, isBrokerage } from '../../utilities/brokerage_utils';
import { Notification } from '../../containers/notifications_page/notifications_page';
import { P, ActionPermission } from '../../utilities/auth/permissions';
import { fullName } from '../../assets/common/string_builders';
import { isAgentRole } from '../../utilities/agent_util';
import { initialState as FilterInitialState } from '../../reducers/product_filter_reducer';
import { ProductFilterPayload, SearchProducts } from '../../actions/product_actions';
import { Strings } from '../../assets/common/strings';
import { AppState } from '../../reducers';
import { FactFinderSlice } from '../../slices';
import { getLatestNotifications } from '../../selectors/notifications_selectors';
import { Dispatch } from '@reduxjs/toolkit';
import { getClientConnectInfo, openClientConnectConfigureDialog } from '../../actions/client_connect_actions';
import { SimpleListItem } from '../utility/simple_list_item';
import { useLinkToExternal, useNavigationProps } from '../../utilities/hooks';
import { openExternalLink } from '../../utilities';
import { updateAppBadge } from '../../utilities/broadcast_util';
import { AppConfig } from '../../types/config';


interface MenuItem {
	title: string;
	icon: React.ReactElement<any>;
	link: () => void;
	badge?: number;
	isHidden?: boolean;
	divider?: boolean;
	permission?: ActionPermission;
	additionalPermission?: () => boolean;
}
interface StateProps {
	isDesktop: boolean;
	agent: AgentState;
	newNotifications: Notification[];
	newNotificationCount: number;
	userID: string;
	impersonatingID: string;
	clientConnect: {
		settings: ClientConnectSettings;
		newActivity: number;
	};
	receivedBattleCount: number;

}
interface DispatchProps {
	startLogout: () => void;
	setDrawerStatus(visible: boolean): void;
	fetchAllNotifications: (userId: string) => void;
	searchProducts: (filterValues: ProductFilterPayload) => void;
	getClientConnectInfo: () => void;
	openClientConnect: () => void;
}
type Props = StateProps & DispatchProps;

let gotCCInfo: boolean = false;
const _SideMenu: React.FC<Props> = (props) => {

	const {
		isDesktop,
		agent,
		newNotifications,
		newNotificationCount,
		userID,
		impersonatingID,
		receivedBattleCount,
		fetchAllNotifications,
		setDrawerStatus,
		startLogout,
		searchProducts,
		getClientConnectInfo,
		openClientConnect,
	} = props;

	const dispatch = useDispatch();
	let { path } = useRouteMatch();
	if (path == '/') {
		path = '';
	}

	const [shouldRefetchNotifications, setShouldRefetchNotifications] = useState(true);
	const { navigateTo } = useNavigationProps();

	const quoteConnectUrl = `
			${themeLinks.quoteConnect}
			dashboard
        `;

	const onClickQuote = useLinkToExternal(quoteConnectUrl);
	useEffect(() => {
		const userId = impersonatingID || userID || '';

		if (newNotifications.length == 0
			&& jwt_auth.hasPermission(P.Notification)
			&& userId
			&& shouldRefetchNotifications
		) {
			fetchAllNotifications(userId);
			setShouldRefetchNotifications(false);
		}
	}, [newNotifications, impersonatingID, userID]);

	useEffect(() => {
		// Get CC info only if size has changed
		if (!gotCCInfo) {
			getClientConnectInfo();
			gotCCInfo = true;
		}
	}, []);

	useEffect(() => {
		updateAppBadge(newNotificationCount);
	}, [newNotificationCount]);

	const navigateAndDismiss = (route: string) => {
		setDrawerStatus(isDesktop);
		navigateTo(path + route);
	};

	const logoff = () => {
		startLogout();
		navigateTo(path + navRoutes.home.path);
	};

	const handlePolicySearchClick = () => {
		const productFilters: ProductFilterPayload = {
			...FilterInitialState,
			productCategoryFilter: 'policies',
			pageNumber: 0,
			pageSize: 20,
		};

		searchProducts(productFilters);
		navigateAndDismiss(navRoutes.productListPage.path)
	};

	const onClickLeadConnect = () => jwt_auth.hasPermission(P.Lead)
		? navigateAndDismiss(navRoutes.leadCircle.path)
		: openExternalLink(AppConfig.lead_connect_link);

	const onClickDocuments = () => isBrokerage
		? openExternalLink(AppConfig.excelsior_broker_docs_link)
		: navigateAndDismiss(navRoutes.documents.path);

	const onClickConnecture = () => {
		const route = `${getRealmUrl()}/#${navRoutes.openConnecture.path}`;
		openExternalLink(route);
	};

	const isVanillaSoftExperience = jwt_auth.isFeatureFlagExists(Strings.FeatureFlag.vanillasoftexperience);
	const rows = useMemo<MenuItem[]>(() => ([
		{
			title: Strings.SideBarMenu.Home,
			icon: <Icon>home</Icon>,
			link: () => navigateAndDismiss(navRoutes.home.path),
		},
		{
			title: Strings.SideBarMenu.Notifications,
			icon: <Icon><SvgIcon type={IconType.Notifications} /></Icon>,
			permission: P.Notification,
			badge: newNotificationCount,
			link: () => navigateAndDismiss(navRoutes.notificationList.path),
		},
		{
			title: Strings.SideBarMenu.ConsumerSearch,
			icon: <Icon className="icon-search" />,
			permission: P.ConsumerSearch,
			additionalPermission: () => !isMobileDevice,
			link: () => navigateAndDismiss(navRoutes.advancedSearch.path.replace(':householdId?/:index?', '')),
			isHidden: isVanillaSoftExperience,
		},
		{
			title: Strings.SideBarMenu.LeadSearch,
			icon: <Icon className="icon-search" />,
			additionalPermission: () => !isMobileDevice,
			permission: P.LeadSearch,
			link: () => navigateAndDismiss(navRoutes.leadSearch.path),
			isHidden: isVanillaSoftExperience,
		},
		{
			title: Strings.SideBarMenu.PolicySearch,
			icon: <Icon className="icon-search" />,
			permission: P.Lead,
			link: handlePolicySearchClick,
			divider: true
		},
		{
			title: Strings.SideBarMenu.LicenseAppointment,
			icon: <Icon><SvgIcon type={IconType.LicenseAppointment} /></Icon>,
			permission: P.LicenseAppointment,
			isHidden: (!AppConfig.is_license_appointment_feature_flag_enabled || AppConfig.is_license_appointment_feature_flag_enabled === undefined) || isBrokerage,
			link: () => navigateAndDismiss(navRoutes.license.path),
		},
		{
			title: Strings.SideBarMenu.ToDoList,
			icon: <Icon><SvgIcon type={IconType.Activities} /></Icon>,
			permission: P.TodoList,
			link: () => navigateAndDismiss(navRoutes.activities.path),
			isHidden: !isBrokerage && isVanillaSoftExperience
		},
		{
			title: Strings.SideBarMenu.CRM,
			icon: <Icon><SvgIcon type={IconType.Crm} /></Icon>,
			permission: P.Lead,
			link: () => navigateAndDismiss(navRoutes.leadDashboard.path),
		},
		{
			title: Strings.SideBarMenu.Leads,
			icon: <Icon><SvgIcon type={IconType.LeadConfig} /></Icon>,
			permission: P.LeadConnectRead,
			link: onClickLeadConnect,
		},
		{
			title: Strings.SideBarMenu.FactFinder,
			icon: <Icon><SvgIcon type={IconType.FactFinder} /></Icon>,
			permission: P.FactFinder,
			isHidden: (!isDesktop && !isTablet()) || isBrokerage,
			link: () => {
				dispatch(FilterSlice.actions.resetFilter({ filterId: riseStrings._FactFinders }));
				dispatch(FactFinderSlice.actions.enableSearch());
				navigateAndDismiss(navRoutes.factFinder.path)
			}
		},
		{
			title: Strings.SideBarMenu.QuoteConnect,
			icon: <Icon><SvgIcon type={IconType.Quote} /></Icon>,
			permission: P.Quote,
			link: onClickQuote,
			divider: isBrokerage,
		},
		{
			title: Strings.SideBarMenu.Connecture,
			icon: <Icon><SvgIcon type={IconType.Connecture} /></Icon>,
			permission: P.Connecture,
			link: () => onClickConnecture()
		},
		{
			title: Strings.SideBarMenu.ClientConnect,
			icon: <Icon><SvgIcon type={IconType.ClientConnect} /></Icon>,
			permission: P.ClientConnect,
			isHidden: isBrokerage,
			link: () => {
				openClientConnect();
				setDrawerStatus(isDesktop);
			},
		},
		{
			title: Strings.SideBarMenu.Performance,
			icon: <Icon><SvgIcon type={IconType.Performance} /></Icon>,
			permission: P.Performance,
			link: () => navigateAndDismiss(navRoutes.performance.path),
			divider: isBrokerage,
		},
		{
			title: Strings.SideBarMenu.Battles,
			icon: <Icon><SvgIcon type={IconType.Battles} /></Icon>,
			permission: P.Battle,
			badge: receivedBattleCount,
			isHidden: true, // battles no longer in side menu - 20.8
			link: () => navigateAndDismiss(navRoutes.battles.path),
		},
		{
			title: Strings.SideBarMenu.Finances,
			icon: <Icon><SvgIcon type={IconType.Finance} /></Icon>,
			permission: P.Finance,
			additionalPermission: () => !isAgentRole([Strings.ADRoles.SubAgent]),
			isHidden: isBrokerage,
			link: () => navigateAndDismiss(navRoutes.finance.path),
			divider: true,
		},
		{
			title: Strings.SideBarMenu.TeamView,
			icon: <Icon><SvgIcon type={IconType.TeamView} /></Icon>,
			permission: P.TeamView,
			isHidden: isBrokerage,
			link: () => navigateAndDismiss(navRoutes.teamview.path),
			divider: true,
		},
		{
			title: Strings.SideBarMenu.Podcasts,
			icon: <Icon><SvgIcon type={IconType.Podcast} /></Icon>,
			permission: P.PodcastRead,
			isHidden: isBrokerage,
			link: () => navigateAndDismiss(navRoutes.podcastList.path),
		},
		{
			title: Strings.SideBarMenu.Documents,
			icon: <Icon><SvgIcon type={IconType.Documents} /></Icon>,
			permission: P.Document,
			link: onClickDocuments,
		},
		{
			title: Strings.SideBarMenu.Tools,
			icon: <Icon><SvgIcon type={IconType.Tools} /></Icon>,
			permission: P.Tool,
			link: () => navigateAndDismiss(navRoutes.tools.path),
			divider: isBrokerage,
		},
		{
			title: Strings.SideBarMenu.News,
			icon: <Icon><SvgIcon type={IconType.News} /></Icon>,
			permission: P.News,
			isHidden: isBrokerage,
			link: () => navigateAndDismiss(navRoutes.home.path),
			divider: true,
		},
		{
			title: Strings.SideBarMenu.AgentSearch,
			icon: <Icon className="icon-search" />,
			permission: P.AgentSearch,
			isHidden: isBrokerage,
			link: () => navigateAndDismiss(navRoutes.agentSearch.path),
		},
		{
			title: Strings.SideBarMenu.Admin,
			icon: <Icon>lock_outline</Icon>,
			additionalPermission: () =>
				!(isMobileDevice && isAgentRole([Strings.ADRoles.Telesales]))
				&& (
					jwt_auth.hasPermission(P.Admin) ||
					jwt_auth.hasPermission(P.Impersonation) ||
					jwt_auth.hasPermission(P.CompanyDnc)
				),
			link: () => navigateAndDismiss(navRoutes.adminLanding.path),
			isHidden: (
				!jwt_auth.hasPermission(P.Admin) &&
				!jwt_auth.hasPermission(P.Impersonation) &&
				!jwt_auth.hasPermission(P.CompanyDnc)
			) && (
					jwt_auth.isHiddenPermission(P.Admin) ||
					jwt_auth.isHiddenPermission(P.Impersonation) ||
					jwt_auth.isHiddenPermission(P.CompanyDnc)
				)
		},
		{
			title: Strings.SideBarMenu.Import,
			icon: <Icon>file_download</Icon>,
			permission: P.Import,
			additionalPermission: () => !isMobileDevice,
			link: () => navigateAndDismiss(navRoutes.import.path),
		},
		{
			title: Strings.SideBarMenu.MyProfile,
			icon: <Icon><SvgIcon type={IconType.Profile} /></Icon>,
			permission: P.Profile,
			link: () => navigateAndDismiss(navRoutes.profile.path),
		},
		{
			title: Strings.SideBarMenu.Settings,
			icon: <Icon><SvgIcon type={IconType.Settings} /></Icon>,
			permission: P.Settings,
			link: () => navigateAndDismiss(navRoutes.settings.path),
		},
		{
			title: Strings.SideBarMenu.HelpAndSupport,
			icon: <Icon>help_outline</Icon>,
			permission: P.Help,
			link: () => navigateAndDismiss(navRoutes.helpSupport.path),
		},
		{
			title: Strings.SideBarMenu.Logout,
			icon: <Icon>exit_to_app</Icon>,
			link: logoff,
		},
	]), [newNotificationCount || isVanillaSoftExperience !== undefined]);

	return (
		<BasePageContainer
			className={"sideMenu"}
			drawerComponent
			topComponent={
				<Grid container style={{ ...styles.searchStyle, backgroundColor: themePalette.header_bar }}>
					<Grid
						item
						container
						direction="column"
						style={styles.topProfileStyle}
						spacing={1}
						onClick={() => {
							jwt_auth.hasPermission(P.Profile)
								? navigateAndDismiss(navRoutes.profile.path)
								: '';
						}}>
						<Grid item>
							<AgentAvatar
								avatarSize={60}
								agent={agent}
								style={{ backgroundColor: themePalette.default_avatar }}
								showInitials={isBrokerage}
							/>
						</Grid>
						<Grid item>
							{fullName(agent)}
						</Grid>
					</Grid>
				</Grid>
			}
		>
			<List>
				{rows.map((row) => {
					let disabled = false;
					let hidden = false;
					if (row.permission) {
						disabled = jwt_auth.isDisablePermission(row.permission);
						hidden = jwt_auth.isHiddenPermission(row.permission);
					}
					if (row.additionalPermission) {
						disabled = disabled || !row.additionalPermission();
					}
					if (hidden || row.isHidden || (row.title === Strings.SideBarMenu.Admin && disabled)) {
						return null;
					}
					return (
						<SimpleListItem
							key={`menu-item-${row.title}`}
							onClick={row.link}
							disabled={disabled}
							divider={!!row.divider}
							icon={row.icon}
							title={row.title}
							hideArrow
							secondaryAction={row.badge && !disabled && (
								<Chip
									color="primary"
									label={row.badge}
								/>
							)}
						/>
					);
				})}
			</List>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => {
	const notifications = getLatestNotifications(state);
	const battleCount: number = 0;
	// Battles no longer in side menu
	// state.battles.battles.filter(currBattle =>
	// 	currBattle.battleStatus == BattleStatuses.requested && currBattle.receivingUser.id == state.user.id
	// ).length;

	return {
		newNotificationCount: notifications.length,
		isDesktop: state.layout.desktop,
		agent: state.agent,
		newNotifications: state.notifications.newNotifications,
		clientConnect: state.clientConnect,
		userID: state.user.id,
		impersonatingID: state.user.impersonatingId,
		receivedBattleCount: battleCount
	};
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	startLogout: () => dispatch(Logout.started(undefined)),
	setDrawerStatus: (visible: boolean) => dispatch(setDrawerStatus(visible)),
	fetchAllNotifications: (userID: string) =>
		dispatch(GetAllNotifications.started(userID)),
	searchProducts: (filterValues: ProductFilterPayload) =>
		dispatch(SearchProducts.started(filterValues)),
	getClientConnectInfo: () => dispatch(getClientConnectInfo.started()),
	openClientConnect: () => dispatch(openClientConnectConfigureDialog.started()),
});

const styles: { [key: string]: React.CSSProperties } = {
	topProfileStyle: {
		color: 'white',
		fontSize: 20,
		cursor: 'pointer',
	},
	searchStyle: {
		padding: 20,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
	},
};

export const SideMenu = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(_SideMenu);
